import React, { Component } from 'react';

class PageTitle extends Component {
	render() {
		return (
			<div className="row">
                <div className="col-md-12">
                    <p className="page-title">{this.props.pageTitle}</p>
					<hr className="hr" />
                </div>
            </div>
		);
	}
}

export default PageTitle;
