import React, { Component } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Body from '../Body/Body';
import './Home.css';

class Home extends Component {
  render() {
    return (
      <section className="wrapper">
        <Header />
        <div className="section-wrapper">
          <Body />
        </div>
        {/* <Footer /> */}
      </section>
    );
  }
}

export default Home;
