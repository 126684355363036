import React, { Component } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './Videos.css';
import SectionDivider from '../Misc/SectionDivider';
import PageTitle from '../Misc/PageTitle';

// Loding images
import DjiPhantomImg from '../../Assets/Img/dji-phantom-review.png';
import SqlServerImg from '../../Assets/Img/install-sql-server.png';
import AndroidNoJvmImg from '../../Assets/Img/android-no-jvm.PNG';
import Windows10PreviewImg from '../../Assets/Img/windows-10-preview.png';
import IcTablePreviewImg from '../../Assets/Img/ic-table.png';
import EiCfgImg from '../../Assets/Img/eicfg.PNG';

class Videos extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <section className="wrapper">
                <Header />
                <div className="section-wrapper">
                    <PageTitle pageTitle="Videos" />

                    {/* Videos wrapper start */}
                    <div className="row justify-content-center videos-wrapper">
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <a href="https://www.youtube.com/watch?v=6rFkiIx6qsw"><img className="card-img-top" src={DjiPhantomImg} /></a>
                                <div className="card-body">
                                <h5 className="card-title">DJI Phantom 3 Professional Quad Copter Unboxing with Short Review</h5>
                                <p className="card-text">Unboxing and a quick review of DJI Phantom 3 Professional Quad Copter. Stay Tuned !</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <a href="https://www.youtube.com/watch?v=szUmT11QYFM"><img className="card-img-top" src={SqlServerImg} /></a>
                                <div className="card-body">
                                <h5 className="card-title">How to Download and Install Microsoft SQL Server 2014</h5>
                                <p className="card-text">The Last few minutes of video doesn't have audio, since my mic was disabled.</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <a href="https://www.youtube.com/watch?v=szUmT11QYFM"><img className="card-img-top" src={AndroidNoJvmImg} /></a>
                                <div className="card-body">
                                <h5 className="card-title">Android Studio No JVM Installation Problem Solution: With Installation method</h5>
                                <p className="card-text">Installation Instructions for Microsoft Windows 10 Technical Preview. For more and download links, log on to : http://www.tekdig.com/microsoft-windows-10-technical-preview/</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Videos wrapper end */}

                    {/* Videos wrapper start */}
                    <div className="row justify-content-center videos-wrapper">
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <a href="https://www.youtube.com/watch?v=xqDO5t7L0_0"><img className="card-img-top" src={Windows10PreviewImg} /></a>
                                <div className="card-body">
                                <h5 className="card-title">Windows 10 Technical Preview Download Instructions</h5>
                                <p className="card-text">Unboxing and a quick review of DJI Phantom 3 Professional Quad Copter. Stay Tuned !</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <a href="https://www.youtube.com/watch?v=tPVYlMH3_E4"><img className="card-img-top" src={IcTablePreviewImg} /></a>
                                <div className="card-body">
                                <h5 className="card-title">IC-7432 Truth Table Verification Using DKT (Digital Trainer Kit)</h5>
                                <p className="card-text">Verification of IC 7432 OR Gate using Digital Trainer Kit.</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <a href="https://www.youtube.com/watch?v=n_2C7LCSR5g"><img className="card-img-top" src={EiCfgImg} /></a>
                                <div className="card-body">
                                <h5 className="card-title">EI.CFG Removal Utility: Make a Universal ISO image of Windows 7</h5>
                                <p className="card-text">Windows 7 disc image comes with all the versions of OS. Its just the key that decides which version to install. With the help of EI.CFG removal tool you can make the disc image universal. This utility unlocks all the versions and allows you to choose which version to install from a single disc.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Videos wrapper end */}
                </div>
                <Footer />
            </section>
        );
    }
}

export default Videos;
