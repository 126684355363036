import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

class Header extends Component {
  render() {
    return (
      //   <div className="header-wrapper">
      // <div className="pull-right header-elements right-header-content">
      // 	<Link to="/">Home</Link>
      // 	<a target="_blank" href="https://tekdig.com">Blog</a>
      // 	<Link to="/bragging-rights">Bragging Rights</Link>
      // 	<Link to="/showcase">Showcase</Link>
      // </div>
      //   </div>

      <div className="header-wrapper">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <a target="_blank" href="https://tekdig.com">Blog</a>
                </li>
                <li className="nav-item">
                  <Link to="/bragging-rights">Bragging Rights</Link>
                </li>
                <li className="nav-item">
                  <Link to="/showcase">Showcase</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Header;
