import React, { Component } from 'react';
import RudrakshImg from '../../Assets/Img/rudraksh-pathak-no-background.png';
import DaffodilImg from '../../Assets/Img/daffodil-software.png';
import MicrosoftImg from '../../Assets/Img/microsoft.png';
import MozillaImg from '../../Assets/Img/firefox.png';
import GithubImg from '../../Assets/Img/github.png';
import IbaImg from '../../Assets/Img/iba-award.png';
import StackoverflowImg from '../../Assets/Img/stack-overflow.png';
import NpmImg from '../../Assets/Img/npm.png';
import YoutubeImg from '../../Assets/Img/youtube.png';
import UnitedAirlinesImg from '../../Assets/Img/united-airlines.png';
import './Body.css';
import Testomonial from './Testimonial';


class Body extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <div className="introduction-wrapper">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="intro-image-wrapper">
                                <img src={RudrakshImg} />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="intro-text-wrapper">
                                <h1><span className="text-bold">Rudraksh</span> Pathak</h1>
                                <label className='label bg-primary'>Senior Engineer</label>
                                <label className='label bg-primary'>Technical Lead</label>
                                <label className='label bg-primary'>Open-source contributor</label>
                            </div>

                            <div className="contact-wrapper">
                                <a href="mailto:connect@rudrakshpathak.com"><i className="fa fa-envelope"></i></a>
                                <a href="https://in.linkedin.com/pub/rudraksh-pathak/25/42/239"><i className="fa fa-linkedin-square"></i></a>
                                <a href="https://twitter.com/rudrapathak"><i className="fa fa-twitter"></i></a>
                                <a target="_blank" href="https://portfolio-rudraksh.s3.ap-south-1.amazonaws.com/rudraksh-pathak-resume.pdf"><i className="fa fa-file-pdf-o"></i></a>
                            </div>

                            <div className="introduction-content-wrapper">
                                <blockquote className="bio-description">
                                    <p>Software engineer having 8 years of experience in building highly available, fault-tolerant products leveraging emerging technologies using industry-standard best practices.</p>
                                </blockquote>

                                {/* <div className='text-center'>
    <label className='skill'>AWS</label>
    <label className='skill'>Microservices</label>
    <label className='skill'>.NET</label>
    <label className='skill'>JavaScript</label>
    <label className='skill'>Laravel</label>
    <label className='skill'>Project Management</label>
</div> */}
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="hr" />

                <div className="row experience-wrapper">
                    <div className="col-md-3">
                        <a href="https://www.united.com/en/us">
                            <figure>
                                <div className="figure">
                                    <img src={UnitedAirlinesImg} className="figure-img img-fluid rounded" alt="United Airlines" />
                                </div>
                                <figcaption className="figure-caption">Senior Engineer, United Airlines</figcaption>
                            </figure>
                        </a>
                    </div>

                    <div className="col-md-3">
                        <a href="https://www.daffodilsw.com/">
                            <figure>
                                <div className="figure">
                                    <img src={DaffodilImg} className="figure-img img-fluid rounded" alt="Daffodil software Ltd" />
                                </div>
                                <figcaption className="figure-caption">Technical Lead, Daffodil Software (2016-2024)</figcaption>
                            </figure>
                        </a>
                    </div>

                    <div className="col-md-3">
                        <a href="https://studentpartners.microsoft.com/en-us">
                            <figure>
                                <div className="figure">
                                    <img src={MicrosoftImg} className="figure-img img-fluid rounded" alt="Microsoft student partner" />
                                </div>
                                <figcaption className="figure-caption">Microsoft Student Partner</figcaption>
                            </figure>
                        </a>
                    </div>

                    <div className="col-md-3">
                        <a href="https://www.mozilla.org/en-US/contribute/studentambassadors/">
                            <figure>
                                <div className="figure">
                                    <img src={MozillaImg} className="figure-img img-fluid rounded" alt="Mozilla student ambassador" />
                                </div>
                                <figcaption className="figure-caption">Mozilla Student Ambassador</figcaption>
                            </figure>
                        </a>
                    </div>

                    
                </div>

                <div className="row experience-wrapper">
                    <div className="col-md-3">
                        <a href="https://github.com/rudrakshpathak">
                            <figure>
                                <div className="figure">
                                    <img src={GithubImg} className="figure-img img-fluid rounded" alt="Open source contributor" />
                                </div>
                                <figcaption className="figure-caption">Open Source Contributor</figcaption>
                            </figure>
                        </a>
                    </div>
                    <div className="col-md-3">
                        <a href="https://stackoverflow.com/users/3903252/rudraksh-pathak">
                            <figure>
                                <div className="figure">
                                    <img src={StackoverflowImg} className="figure-img img-fluid rounded" alt="Stackoverflow contributor" />
                                </div>
                                <figcaption className="figure-caption">Stack Overflow Contributor</figcaption>
                            </figure>
                        </a>
                    </div>

                    {/* <div className="col-md-3">
                        <a href="https://www.youtube.com/c/TekdigDotCom">
                            <figure>
                                <div className="figure">
                                    <img src={YoutubeImg} className="figure-img img-fluid rounded" alt="Content creator<" />
                                </div>
                                <figcaption className="figure-caption">Content creator</figcaption>
                            </figure>
                        </a>
                    </div> */}
                    <div className="col-md-3">
                        <a href="https://www.npmjs.com/~rudrakshpathak">
                            <figure>
                                <div className="figure">
                                    <img src={NpmImg} className="figure-img img-fluid rounded" alt="npm Contributor" />
                                </div>
                                <figcaption className="figure-caption">npm Contributor</figcaption>
                            </figure>
                        </a>
                    </div>

                    <div className="col-md-3">
                        <a href="https://www.indiblogger.in/iba/2013/">
                            <figure>
                                <div className="figure">
                                    <img src={IbaImg} className="figure-img img-fluid rounded" alt="IBA award winner" />
                                </div>
                                <figcaption className="figure-caption">Award Winning Blogger</figcaption>
                            </figure>
                        </a>
                    </div>
                </div>

                <hr className="hr" />

                <div className='testimonial-header'>
                    <h3>What people have to <span className="text-bold"> say</span></h3>
                    <Testomonial />
                </div>
            </div>
        );
    }
}

export default Body;
