import React, { Component } from 'react';
import './Testimonial.css';
import { Carousel } from '@trendyol-js/react-carousel';

import MohitImg from '../../Assets/Img/testimonial/mohit-singh.jpg';
import SachinImg from '../../Assets/Img/testimonial/sachin-vaid.jpg';
import HarshitImg from '../../Assets/Img/testimonial/harshit-gupta.jpg';
import AshishImg from '../../Assets/Img/testimonial/ashish-dawar.jpg';
import SameerImg from '../../Assets/Img/testimonial/sameer-singh.jpg';

class Testimonial extends Component {
    render() {
        return (
            <Carousel show={1} swiping={true} responsive={true} infinite={false} useArrowKeys={true}>
                <div className="testimonial-wrapper">
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='testimonial-image'>
                                <figure>
                                    <img src={SachinImg} className='rounded' />
                                    <figcaption className="figure-caption"><a target='_blank' href='https://www.linkedin.com/in/rudrakshpathak/details/recommendations/?detailScreenTabIndex=0'>Sachin Vaid</a></figcaption>
                                </figure>
                            </div>
                        </div>
                        <div className='col-md-9'>
                            <blockquote className='testimonial-content'>I am writing to highly recommend Rudraksh for any professional opportunity that may come his way. During our time working together, I have been consistently impressed with Rudraksh's exceptional skills in different programming languages and his quick learning ability.
                                Not only is Rudraksh a skilled programmer, but he is also an outstanding solution architect with a solutioning approach. His ability to understand complex business requirements and design effective solutions has been a great asset to our team. Additionally, his leadership as a people manager has helped to create a positive and collaborative work environment.
                                One of Rudraksh's greatest strengths is his willingness to help others. He is always ready to lend a hand, share his knowledge, and collaborate with team members to achieve common goals. His dedication to teamwork and his positive attitude are truly contagious and have had a positive impact on our team.
                                I would highly recommend Rudraksh for any role that requires a skilled programmer, a talented solution architect, or a great people manager. He would be an asset to any organization lucky enough to have him on their team
                            </blockquote>
                        </div>
                    </div>
                </div>
                <div className="testimonial-wrapper">
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='testimonial-image'>
                                <figure>
                                    <img src={MohitImg} className='rounded' />
                                    <figcaption className="figure-caption"><a target='_blank' href='https://www.linkedin.com/in/rudrakshpathak/details/recommendations/?detailScreenTabIndex=0'>Mohit Kumar Singh</a></figcaption>
                                </figure>
                            </div>
                        </div>
                        <div className='col-md-9'>
                            <blockquote className='testimonial-content'>
                                I am writing to recommend Rudraksh Pathak, who is an exceptional professional with a passion for technology and a dedication to his work. During our time working together, I have been impressed by his commitment to excellence and his ability to learn new technologies quickly.
                                Rudraksh is a technology enthusiast who is always open to working on new and emerging technologies. he is eager to learn and experiment with new tools and frameworks, which has made him a valuable asset to our team. His dedication to his work is second to none, and he is always willing to go above and beyond to ensure that projects are completed on time and to the highest standards.
                                I wholeheartedly recommend Rudraksh Pathak for any position in the technology industry. his dedication, technical expertise, and excellent communication skills make him a valuable addition to any team or organization.
                            </blockquote>
                        </div>
                    </div>
                </div>
                <div className="testimonial-wrapper">
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='testimonial-image'>
                                <figure>
                                    <img src={HarshitImg} className='rounded' />
                                    <figcaption className="figure-caption"><a target='_blank' href='https://www.linkedin.com/in/rudrakshpathak/details/recommendations/?detailScreenTabIndex=0'>Harshit Gupta</a></figcaption>
                                </figure>
                            </div>
                        </div>
                        <div className='col-md-9'>
                            <blockquote className='testimonial-content'>It is my pleasure to highly recommend Rudrash for any opportunity that will come his way as a Technical Lead or Solutions Architect. We worked together for almost 5 years and I have seen him grow from a software developer to a tech lead and solutions architect. He is always a go to man for a manager as he delivers solutions beyond expectations and is so sound technically that he always impresses with his work.
                                He has a great mind with a eagerness to learn and constantly improve. His ability to understand complex problems and to come up with a perfect solution has always been outstanding.
                                Along with all his technical capabilities and skills, he is also a great human being with empathy and he treats everyone with respect and is always the first one to help someone. Without any doubt, Rudraksh will be a great asset to any company for which he is going to work.
                            </blockquote>
                        </div>
                    </div>
                </div>

                <div className="testimonial-wrapper">
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='testimonial-image'>
                                <figure>
                                    <img src={AshishImg} className='rounded' />
                                    <figcaption className="figure-caption"><a target='_blank' href='https://www.linkedin.com/in/rudrakshpathak/details/recommendations/?detailScreenTabIndex=0'>Ashish Dawar</a></figcaption>
                                </figure>
                            </div>
                        </div>
                        <div className='col-md-9'>
                            <blockquote className='testimonial-content'>I am pleased to write this recommendation for Rudraksha, as he is truly a valuable team member. During our time working together, I have found Rudraksha to be extremely knowledgeable and smart. His dedication and hard work have been an asset to our team and have helped us achieve our goals.
                                What impressed me the most about Rudraksha was when he transformed from a PHP developer to a .NET developer. He was able to quickly learn new technologies and adapt to new challenges. This shows his willingness to learn and grow as a professional, which is a rare quality to find.
                                Moreover, Rudraksha's leadership qualities are impressive. As he progressed in his career, he became a lead and a pivotal member of our team. He demonstrated excellent management skills and helped us stay on track with our projects. He is an excellent communicator and is always willing to go the extra mile to get things done.
                                In summary, I highly recommend Rudraksha for any position he may apply for. He is an exceptional team member, and I am confident that he will continue to excel in his career.
                            </blockquote>
                        </div>
                    </div>
                </div>

                <div className="testimonial-wrapper">
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='testimonial-image'>
                                <figure>
                                    <img src={SameerImg} className='rounded' />
                                    <figcaption className="figure-caption"><a target='_blank' href='https://www.linkedin.com/in/rudrakshpathak/details/recommendations/?detailScreenTabIndex=0'>Sameer Singh</a></figcaption>
                                </figure>
                            </div>
                        </div>
                        <div className='col-md-9'>
                            <blockquote className='testimonial-content'>
                                I am writing to highly recommend Rudraksh, who was my colleague and a valuable member of our team. Rudraksh served as a Solution Architect during his tenure with us and consistently demonstrated exceptional technical and leadership skills. His technical expertise, attention to detail, and commitment to delivering high-quality work makes him an invaluable member of the project team. He is a team player with excellent communication skills and a positive attitude.
                                I would highly recommend Rudraksh to any organization seeking a talented Solution Architect who can provide expert technical guidance and lead a team to success.
                            </blockquote>
                        </div>
                    </div>
                </div>
            </Carousel>

        );
    }
}

export default Testimonial;
