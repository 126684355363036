import React, { Component } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './Showcase.css';
import PageTitle from '../Misc/PageTitle';

// Loding images
import ToasterImg from '../../Assets/Img/msg-toaster.png';
import TechMagImg from '../../Assets/Img/tekdig-techmag-img.png';
import ExcelJsonImg from '../../Assets/Img/excel-to-json-img.png';
import AirPollutionImg from '../../Assets/Img/air-pollution-checker-img.png';
import Angular2GoogleLoginImg from '../../Assets/Img/angular2-google-login-thumb.png';
import EpicImg from '../../Assets/Img/epic.jpg';
import WordCounterImg from '../../Assets/Img/word-counter.png';
import OesImg from '../../Assets/Img/oes.png';
import InventoryImg from '../../Assets/Img/inventory.png';
import NotePortImg from '../../Assets/Img/noteport.png';
import PingToolImg from '../../Assets/Img/ping.png';
import TscImg from '../../Assets/Img/tsc.png';
import ApiKeyGenerator from '../../Assets/Img/api-key-generator-img.png';
import Crypter from '../../Assets/Img/crypter-img.png';
import Dastavej from '../../Assets/Img/dastavej.jpg';
import Regexer from '../../Assets/Img/regexer.jpg';
import Toutt from '../../Assets/Img/toutt.png';
import GoodsTrolley from '../../Assets/Img/goods-trolley.png';
import AwsServices from '../../Assets/Img/aws-services.png';

class Showcase extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <section className="wrapper showcase-section">
                <Header />
                <div className="section-wrapper">
                    <PageTitle pageTitle="Showcase" />

                    {/* Showcase wrapper start */}
                    <div className="row justify-content-center showcase-wrapper">
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={AwsServices} />
                                <div className="card-body">
                                    <h5 className="card-title">AWS Services SDK wrapper</h5>
                                    <p className="card-text">A NodeJS based wrapper for quick usage of AWS services. A plug and play wrapper which allows developers to reduce the setup cost of services and SDKs.</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Tool</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td>
                                                    <label className="label ts-label">TypeScript</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td><a href='https://github.com/rudrakshpathak/aws-services'>rudrakshpathak/aws-services</a></td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td><a href='https://www.npmjs.com/package/aws-services-sdk-wrapper'>aws-services-sdk-wrapper</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>26-April-2022</span>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={Dastavej} />
                                <div className="card-body">
                                    <h5 className="card-title">Dastavej</h5>
                                    <p className="card-text">Dastavej is a secure space for documents and information.Dastavej provides you a streamline process of storing your information in a secured environemnt.</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Application</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td>
                                                    <label className="label html-label">HTML</label>
                                                    <label className="label css-label">CSS</label>
                                                    <label className="label jquery-label">jQUery</label>
                                                    <label className="label laravel-label">Laravel</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td><a href="https://dastavej.app/">Dastavej</a></td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td>NA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>15-February-2020</span>
                                    </small>
                                </div>
                            </div>
                        </div>
                        {/* <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={GoodsTrolley} />
                                <div className="card-body">
                                    <h5 className="card-title">GoodsTrolley</h5>
                                    <p className="card-text">A web application to solve the routing problem of goods distribution.
Utilizing Google maps and third-party routing APIs to generate optimal routes to
deliver goods from origin to distinct locations while maintaining the inventory and invoicing.</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Application</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td>
                                                    <label className="label html-label">HTML</label>
                                                    <label className="label css-label">CSS</label>
                                                    <label className="label jquery-label">jQUery</label>
                                                    <label className="label laravel-label">Laravel</label>
                                                    <label className="label php-label">PHP</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td>NA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>NA</span>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={Toutt} />
                                <div className="card-body">
                                    <h5 className="card-title">Kinektis</h5>
                                    <p className="card-text">An application having multiple tools to provide time-tracking, invoicing, and client
management by utilizing the different AWS services and Laravel framework.</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Application</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td>
                                                    <label className="label html-label">HTML</label>
                                                    <label className="label css-label">CSS</label>
                                                    <label className="label jquery-label">jQUery</label>
                                                    <label className="label laravel-label">Laravel</label>
                                                    <label className="label php-label">PHP</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td>NA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>NA</span>
                                    </small>
                                </div>
                            </div>
                        </div> */}
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={Regexer} />
                                <div className="card-body">
                                    <h5 className="card-title">Regexer</h5>
                                    <p className="card-text">Regexer is a tool to test your regex and patterns.</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Tool</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td>
                                                    <label className="label react-label">ReactJS</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td><a href="http://regexer.rudrakshpathak.com/">Regexer</a></td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td>NA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>22-February-2020</span>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={Crypter} />
                                <div className="card-body">
                                    <h5 className="card-title">Crypter</h5>
                                    <p className="card-text">Encrypt and Decrypt the strings using the salt of your choice using the encryption techniques from CryptoJs</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Tool</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td><label className="label js-label">JavaScript</label></td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td><a href="http://content.rudrakshpathak.com/crypter">Crypter</a></td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td>NA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>01-January-2019</span>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={ApiKeyGenerator} />
                                <div className="card-body">
                                    <h5 className="card-title">API Key Generator</h5>
                                    <p className="card-text">Generate API keys with your choice of hashing technique.</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Tool</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td><label className="label js-label">JavaScript</label></td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td><a href="http://content.rudrakshpathak.com/api-key-generator/">API Key Generator</a></td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td>NA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>01-January-2019</span>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={ToasterImg} />
                                <div className="card-body">
                                    <h5 className="card-title">jQuery Message Toaster</h5>
                                    <p className="card-text">A Non-blocking, simple and easy to use jQuery based message toaster. Fully configurable, and customizable options available.</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Plugin</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td><label className="label js-label">JavaScript</label></td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td><a href="http://content.rudrakshpathak.com/toaster">ToasterJs</a></td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td><a href="https://github.com/rudrakshpathak/jquery-message-toaster">rudrakshpathak/jquery-message-toaster</a></td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td><a href="https://www.npmjs.com/package/jquery-message-toaster">jquery-message-toaster</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>01-December-2017</span>
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={TechMagImg} />
                                <div className="card-body">
                                    <h5 className="card-title">Tekdig Tech Mag - Revised</h5>
                                    <p className="card-text">Tekdig is a website that provides latest information about gadgets like features, price, technical specifications, user reviews, videos and more.</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Web Application</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td>
                                                    <label className="label html-label">HTML</label>
                                                    <label className="label css-label">CSS</label>
                                                    <label className="label jquery-label">jQUery</label>
                                                    <label className="label laravel-label">Laravel</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td><a href="https://www.tekdig.com/">Tekdig</a></td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td>NA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>07-November-2017</span>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={ExcelJsonImg} />
                                <div className="card-body">
                                    <h5 className="card-title">Excel to JSON Converter</h5>
                                    <p className="card-text">A simple excel to json converter using SheetJs(http://sheetjs.com) library. Just upload the file select the worksheet and its done.</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Utility</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td>
                                                    <label className="label html-label">HTML</label>
                                                    <label className="label css-label">CSS</label>
                                                    <label className="label jquery-label">jQUery</label>
                                                    <label className="label laravel-label">Laravel</label>
                                                    <label className="label php-label">PHP</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td><a href="http://content.rudrakshpathak.com/excel-to-json/">Excel to JSON converter</a></td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td>NA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>04-October-2017</span>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={AirPollutionImg} />
                                <div className="card-body">
                                    <h5 className="card-title">Air Pollution Checker</h5>
                                    <p className="card-text">A simply utility to check air pollution caused by vehicles in your city. Select state and city to get the information. All the data has been captured from data.gov.in</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Web Application</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td>
                                                    <label className="label html-label">HTML</label>
                                                    <label className="label css-label">CSS</label>
                                                    <label className="label jquery-label">jQUery</label>
                                                    <label className="label laravel-label">Laravel</label>
                                                    <label className="label php-label">PHP</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td><a href="https://www.tekdig.com/check-pollution/">Air Pollution Checker</a></td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td>NA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>02-October-2017</span>
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={Angular2GoogleLoginImg} />
                                <div className="card-body">
                                    <h5 className="card-title">Angular2 Google Login</h5>
                                    <p className="card-text">Google oAuth authentication. This repository implements Google login using Angular2.</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Plugin</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td>
                                                    <label className="label ts-label">TypeScript</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td><a href="http://content.rudrakshpathak.com/angular2-google-login">Angular2 Google Login</a></td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td><a href="https://github.com/rudrakshpathak/angular2-google-login">rudrakshpathak/angular2-google-login</a></td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td><a href="https://www.npmjs.com/package/angular2-google-login">angular2-google-login</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>05-May-2017</span>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={EpicImg} />
                                <div className="card-body">
                                    <h5 className="card-title">E.P.I.C. - Emulated Portal Interpreting Client</h5>
                                    <p className="card-text">A Gesture based application built with a team of 3 members. Using the concept of virtualization and emulation, we aim to assemble a tangible machine based on the studies conducted on ‘E.P.I.C.’ so that the behavior of the tangible assembled machine is in coherence to the behavior of the intangible assembled machine on E.P.I.C.</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Web Application</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td>
                                                    <label className="label js-label">JavaScript</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td><a href="https://github.com/rudrakshpathak/Swap-Grab-LeapMotion-Gestures">EPIC</a></td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td>NA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>16-February-2016</span>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={WordCounterImg} />
                                <div className="card-body">
                                    <h5 className="card-title">Dynamic Word Counter</h5>
                                    <p className="card-text">Dynamic Word Counter Counts the number of words as you type in the box. The Box is small application and build using common web technologies.</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Plugin</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td>
                                                    <label className="label html-label">HTML</label>
                                                    <label className="label css-label">CSS</label>
                                                    <label className="label jquery-label">jQUery</label>
                                                    <label className="label php-label">PHP</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td><a href="http://content.rudrakshpathak.com/word-counter">Word counter</a></td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td>NA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>23-December-2015</span>
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={OesImg} />
                                <div className="card-body">
                                    <h5 className="card-title">Video Sharing System</h5>
                                    <p className="card-text">Video Sharing System is built using Web technologies like (HTML5, CSS3, JavaScript, PHP, Ajax). Currently implemented in Krishna Engineering College, Ghaziabad. The VSM is built to share short videos. Anyone can upload the videos by signing-in. The Uploaded video is available for everyone.</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Plugin</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td>
                                                    <label className="label html-label">HTML</label>
                                                    <label className="label css-label">CSS</label>
                                                    <label className="label jquery-label">jQUery</label>
                                                    <label className="label php-label">PHP</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td>NA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>02-May-2015</span>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={OesImg} />
                                <div className="card-body">
                                    <h5 className="card-title">Online Examination System</h5>
                                    <p className="card-text">Online Examination System is built using Web technologies like (HTML5, CSS3, JavaScript, PHP, Ajax). Currently implemented in Krishna Engineering College, Ghaziabad. An Admin or a college Faculty can insert the questions. Admin has rights to conduct the examination and view reports of the same.</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Plugin</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td>
                                                    <label className="label html-label">HTML</label>
                                                    <label className="label css-label">CSS</label>
                                                    <label className="label jquery-label">jQUery</label>
                                                    <label className="label php-label">PHP</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td>NA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>04-April-2015</span>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={InventoryImg} />
                                <div className="card-body">
                                    <h5 className="card-title">e-Inventory System</h5>
                                    <p className="card-text">e-Inventory System is built using Web technologies like (HTML5, CSS3, JavaScript, ASP.NET, C#, Ajax). Currently implemented in Krishna Engineering College, Ghaziabad. An Admin or a store in-charge can insert and manipulate the entries in the stock register. Admin has rights to take out various reports and official data for records.</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Plugin</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td>
                                                    <label className="label net-label">.NET</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td>NA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>09-January-2015</span>
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={NotePortImg} />
                                <div className="card-body">
                                    <h5 className="card-title">NotePort - Notes Sharing Application</h5>
                                    <p className="card-text">Noteport is built using Web technologies like (HTML5, CSS3, JavaScript, PHP, Ajax). Currently implemented in Krishna Engineering College, Ghaziabad. Any Faculty can add or remove digital notes that are visible to all the students.</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Plugin</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td>
                                                    <label className="label html-label">HTML</label>
                                                    <label className="label css-label">CSS</label>
                                                    <label className="label jquery-label">jQUery</label>
                                                    <label className="label php-label">PHP</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td>NA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>01-January-2015</span>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={PingToolImg} />
                                <div className="card-body">
                                    <h5 className="card-title">Ping Tool</h5>
                                    <p className="card-text">Ping Tool is simple Network ping tool built using Java Library. The tool simply invokes the command shell of the host Operating System and executes the ping command. The output is displayed in the GUI of tool. It requires JDK 1.4.0 to run.</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Plugin</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td>
                                                    <label className="label java-label">Java</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td>NA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>11-December-2014</span>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="card-deck col-sm-4">
                            <div className="card">
                                <img className="card-img-top" src={TscImg} />
                                <div className="card-body">
                                    <h5 className="card-title">Time Span Calculator</h5>
                                    <p className="card-text">A Time Span Calculator that calculates how much time you have spent on Earth. Just submit your Date of Birth and left it over to the app. The TSC will calculate the result in Years, Days, Hours, Minutes.</p>

                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Category</th>
                                                <td>Plugin</td>
                                            </tr>
                                            <tr>
                                                <th>Built Using</th>
                                                <td>
                                                    <label className="label net-label">C#</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Info/Demo</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>Github</th>
                                                <td>NA</td>
                                            </tr>
                                            <tr>
                                                <th>NPM</th>
                                                <td>NA</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer">
                                    <small className="text-muted">
                                        <span className="font-bold">Release date: </span>
                                        <span>06-April-2014</span>
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Showcase wrapper end */}
                </div>
                {/* <Footer /> */}
            </section>
        );
    }
}

export default Showcase;
