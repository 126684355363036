import React, { Component } from 'react';
import Home from '../Components/Home/Home';
import Showcase from '../Components/Showcase/Showcase';
import Bragging from '../Components/Bragging/Bragging';
import Videos from '../Components/Videos/Videos';
import { Route, Switch } from 'react-router-dom';

/**
 * Class to defnine routes in a single place
 */
export default class AppRoutes extends Component {
    render() {
        return (
            <Switch onUpdate={() => window.scrollTo(0, 0)}>
                <Route exact path='/' component={Home} />
                <Route exact path='/showcase' component={Showcase} />
                <Route exact path='/bragging-rights' component={Bragging} />
                <Route exact path='/videos' component={Videos} />
            </Switch>
        )
    }
}