import React, { Component } from 'react';

class SectionDivider extends Component {
	render() {
		return (
			<div className="row">
                <div className="col-md-12">
                    <p className="section-divider">{this.props.barTitle}</p>
                </div>
            </div>
		);
	}
}

export default SectionDivider;
