import React, { Component } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './Bragging.css';
import SectionDivider from '../Misc/SectionDivider';
import PageTitle from '../Misc/PageTitle';

// Loding images
import WomenInTechImg from '../../Assets/Img/women-in-tech.png';
import BlogZeeNewsImg from '../../Assets/Img/blog-on-zee-news.jpg';
import AwardWinningBlogImg from '../../Assets/Img/award-winning-blogger.jpg';
import GuestWriterImg from '../../Assets/Img/guest-writer.png';

class Bragging extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <section className="wrapper bragging-section">
                <Header />
                <div className="section-wrapper">
                    <PageTitle pageTitle="Bragging Rights" />

                    {/* Bragging wrapper start */}

                    <div className="row justify-content-center bragging-wrapper">
                        <div className="card-deck col-sm-6">
                            <div className="card">
                                <img className="card-img-top" src={WomenInTechImg} />
                                <div className="card-body">
                                    <h5 className="card-title">Microsoft Women in Tech</h5>
                                    <p className="card-text">In 2014, after becoming Microsoft Student Partner, I contributed in Microsoft's Women in Tech initiative where I was given the responsibility to spread the awareness of technology among women.</p>                                    
                                </div>
                            </div>                    
                        </div>
                    
                        <div className="card-deck col-sm-6">
                            <div className="card">
                                <img className="card-img-top" src={BlogZeeNewsImg} />
                                <div className="card-body">
                                    <h5 className="card-title">Blog on Zee News</h5>
                                    <p className="card-text">On March 31, 2013 Tekdig's comment was used as a reference on <a href="https://zeenews.india.com/exclusive/google-nexus-7-vs-ipad-mini-vs-galaxy-tab-2_6204.html">Zee News website</a> while comparing gadgets.</p>                                    
                                </div>
                            </div>                    
                        </div>
                    
                        <div className="card-deck col-sm-6">
                            <div className="card">
                                <img className="card-img-top" src={AwardWinningBlogImg} />
                                <div className="card-body">
                                    <h5 className="card-title">Award Winning Blogger</h5>
                                    <p className="card-text">In 2013, I was awarded as one of the <a href="https://www.indiblogger.in/iba/2013/">best teenage blogger</a> of India by IndiBlogger.</p>                                    
                                </div>
                            </div>                    
                        </div>
                    
                        <div className="card-deck col-sm-6">
                            <div className="card">
                                <img className="card-img-top" src={GuestWriterImg} />
                                <div className="card-body">
                                    <h5 className="card-title">Guest Writer on Top Blogs</h5>
                                    <p className="card-text">
                                        Articles have been published on many top blogs as a guest author for their blogs like ShoutMeLoud, Search Engine Journal, <a href="http://onlineincometeacher.com/review/beginners-guide-to-domain-authority/">Online Incoming Teacher</a>, <a href="https://hellboundbloggers.com/the-concept-of-nofollow-tag-in-blogging/46100/">HellBoubd bloggers</a>, <a href="https://www.blogsolute.com/compete-fellow-bloggers/26814/">Blogsolute</a>, <a href="https://oddblogger.com/how-to-build-blogging-relations-that-lasts-forever  ">OddBlogger</a>, TechBappa, <a href="https://www.itechcode.com/2012/08/02/strategies-to-improve-your-blog-reputation-and-page-rank/">iTechCode</a>
                                    </p>                                    
                                </div>
                            </div>                    
                        </div>
                    </div>

                    {/* Bragging wrapper end */}
                </div>
                {/* <Footer /> */}
            </section>
        );
    }
}

export default Bragging;
